<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Restore Acces</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading
            :is-full-page="false"
            :active.sync="isLoading"
            :can-cancel="false"
          ></b-loading>

          <div v-if="form.visible">
            <form autocomplete="off">
              <b-field
                label="Email"
                :type="form.email.status"
                :message="form.email.message"
              >
                <b-input
                  type="email"
                  v-model="form.email.data"
                  placeholder="Your Email"
                  expanded
                ></b-input>
              </b-field>
            </form>
          </div>

          <b-message class="my-2" :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <b-button type="is-primary" @click="closeModal()">
              Close
            </b-button>
          </div>

        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <b-button type="is-light" @click="closeModal()">Cancel</b-button>

          <b-button
            type="is-info"
            @click="restoreAccess()"
            :loading="isLoading"
          >
            Restore Access
          </b-button>

        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";
export default {
  name: "RestoreModal",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        email: {
          data: "",
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
      },
    };
  },
  methods: {

    restoreAccess() {
      if (this.emailCheck()) {
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/restore.php", {
            action: "restore",
            email: this.form.email.data,
            token: this.$store.state.tempToken,
          })
          .then((response) => {
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
            } else {
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            console.log(error);
            //this.hasError();
          })
          .finally(() => {
            this.isLoading = false;
          });
        // //end post-------------------------------------------------
      }
    },


    resetForm() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";

      this.form.message = "";
      this.form.result = false;
      this.form.status = "";

      this.isLoading = false;
      this.countErrors = 0;
      this.form.visible = true;
    },

    closeModal() {
      this.resetForm();
      this.$store.commit("modals/hideModal", "restore");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.restore;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "restore");
        return false;
      },
    },
  },
  watch: {
    isActive: function () {
      this.resetForm();
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
