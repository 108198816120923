import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import users from "./modules/users";
import modals from "./modules/modals";
import toast from "./modules/toast";

let apiUrl = process.env.VUE_APP_API_URL;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showMenu: false,
    isMobile: false,
    tempToken: null,
    clientID: null,
  },
  getters: {
  },
  mutations: {   
    setMenu(state, payload) {
      console.log("setMenu:", payload);
      state.showMenu = payload;
    },
    setMobile(state, payload) {
      console.log("setMobile:", payload);
      state.isMobile = payload;
    },    
    setTempToken(state, payload) {
      console.log("setTempToken:", payload);
      state.tempToken = payload;
    },  
    setClientID(state, payload) {
      console.log("setClientID:", payload);
      state.clientID = payload;
    }
  },
  actions: {
    getTempToken({ commit }) {
      axios
        .post(`${apiUrl}/libs/token.php`, {
          action: "getToken",
        })
        .then((response) => {
          //console.log("token response", response);
          if (response.data.success === false) {
            console.log("error");
            return;
          }
          let token = response.data.token;
          commit("setTempToken", token);
        })
        .catch((error) => {
          console.log("token error ",error);
        });
    },
  },
  modules: {
    users,
    modals,
    toast,
  },
});
