export default {
  methods: {
    checlClient() {
      if (typeof crccli === "undefined") {
        console.log("CRCCLI NOT FOUND!");
        if (this.$route.name !== "LoginView") {
          this.$router.push({ path: "/" }).catch((err) => {
            console.log(err);
          });
        }
        this.showAlert();
      } else {
        console.log("CRCCLI FOUND!");
        console.log(crccli);
        this.$store.commit("setClientID", crccli);
        return crccli;
      }
    },
    showAlert() {
        this.$buefy.dialog.alert({
          title: "Error",
          message:
            "It seems that you are outside of CSB Dash. Please use the CSB Dash to access the system.",
          type: "is-danger",
          hasIcon: true,
          icon: "triangle-exclamation",
          iconPack: "syntho",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
  },
};
