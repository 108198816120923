<template>
  <b-navbar
    class="shadow"
    wrapper-class="container"
    :mobile-burger="false"
    :fixed-top="true"
    type="is-mydark"
  >
    <template #brand>
      <b-navbar-item
        class="is-mydark"
      >
        <img src="@/assets/csb_logo_admin_light.svg" alt="" width="50px">
      </b-navbar-item>
    </template>
    <!-- <template #start>
      <b-navbar-item @click="logout()"> Logout </b-navbar-item>
    </template> -->

    <template #end>
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
        Dashboard
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/account' }">
        Account
      </b-navbar-item>
      <b-navbar-item @click="logout()"> Logout </b-navbar-item>
    </template>
  </b-navbar>
</template>

<style scoped>
.shadow {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.17);
}
</style>


<script>
import { mapState } from "vuex";
import toast from "@/mixins/toast";

export default {
  name: "Navbar",
  mixins: [toast],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    //...mapState(["sidebarShow"]),
  },
  methods: {
    logout() {
      this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("/users/logout.php", {
            token: localStorage.CSBToken,
            action: "logout",
          })
          .then((response) => {
            this.isLoading = false;          
            console.log(response.data);
            if (response.data.success) {
              this.toast("Logged out successfully", "is-success");
            } else {
              this.toast("Error logging out", "is-danger");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          })
          .finally(() => {
            this.$store.commit("users/logoutUser");
            this.$router.push({ path: "/" }).catch((err) => {
              console.log(err);
            });
          });
    },
  },
};
</script>
